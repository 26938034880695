<template>
  <div class="customer-care-pages contact-us-page">
    <b-row class="layout-with-shoulder">
      <b-col cols="12" class="shoulder-left">
      <div  class="stiky-position">
        <customer-care-link/>
      </div>
      </b-col>
      <b-col cols="12" class="shoulder-content">
        <modalTerms v-model="modalTerms.open" :pageID="modalTerms.pageID" v-on:close="openModalTerms ({open: false, pageID: ''})"></modalTerms>
        <div class="customer-care-content__header"></div>
        <div class="customer-care-content__body">
          <b-row>
            <b-col sm="8" class="column-left">
              <div class="content-title">
                <h1 class="title">{{ $t('customerCare.contact.page-title')}}</h1>
              </div>
              <div class="contact-form">
                <div class="form-intro">
                  <p class="intro-subtitle" v-html="$t('customerCare.contact.form-intro-subtitle')"></p>
                </div>
                <form @submit.prevent="sendRequest" class="form-contact">
                  <div class="mandatory-field">
                    <span>*{{ $t('mandatory-field') }}</span>
                  </div>
                  <b-row>
                    <b-col sm="12" md="6">
                      <b-form-group id="groupFirstname">
                        <base-input
                          name="firstname"
                          v-validate="'required'"
                          v-model="form.firstname"
                          type="text"
                          :placeholder="$t('address.FirstName') + ' *'"
                          :validations="[
                            {
                              condition: errors.has('firstname'),
                              text: errors.first('firstname')
                            }
                          ]"
                        ></base-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                      <b-form-group id="groupLastName">
                        <base-input
                          name="lastname"
                          v-validate="'required'"
                          :placeholder="$t('address.LastName') + ' *'"
                          v-model="form.lastname"
                          type="text"
                          :validations="[
                            {
                              condition: errors.has('lastname'),
                              text: errors.first('lastname')
                            }
                          ]"
                        ></base-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" md="6">
                      <b-form-group id="groupCity">
                        <base-input
                          name="city"
                          v-validate="'required'"
                          v-model="form.city"
                          type="text"
                          :placeholder="$t('address.City') + ' *'"
                          :validations="[
                            {
                              condition: errors.has('city'),
                              text: errors.first('city')
                            }
                          ]"
                        ></base-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                      <b-form-group id="groupCountry" label-for="country">
                        <BaseSelect
                        name="country"
                        id="country"
                        v-model="form.country"
                        v-validate="'required'"
                        :options="optionsCountries"
                        :reduce="opt => opt.value"
                        selectClass="select-shadowed"
                        :placeholder="$t('address.Country') + ' *'"
                        :validations="[
                          {
                            condition: errors.has('country'),
                            text: errors.first('country')
                          }
                        ]"
                        >
                        </BaseSelect>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" md="6">
                      <b-form-group id="groupEmail">
                        <base-input
                          name="email"
                          v-validate="'required|email'"
                          v-model="form.email"
                          type="email"
                          :placeholder="$t('address.Email') + ' *'"
                          :validations="[
                            {
                              condition: errors.has('email'),
                              text: errors.first('email')
                            }
                          ]"
                        ></base-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="align-items-end">
                    <b-col sm="12" md="6">
                      <b-form-group id="groupContactReason" label-for="contactreason">
                        <BaseSelect
                          v-model="form.reason"
                          id="contactreason"
                          name="contactreason"
                          v-validate="'required'"
                          :options="selectReasons"
                          selectClass="select-shadowed"
                          :placeholder="$t('customerCare.contact.contact_reason') + ' *'"
                          :validations="[
                            {
                              condition: errors.has('contactreason'),
                              text: errors.first('contactreason')
                            }
                          ]"
                        >
                        </BaseSelect>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                      <b-form-group id="groupOrderNumber">
                        <base-input
                          name="ordernumber"
                          v-validate="orderRequired"
                          v-model="form.ordernumber"
                          type="text"
                          :placeholder="$t('customerCare.contact.order_number') + (orderRequired ? ' *' : '')"
                          :validations="[
                            {
                              condition: errors.has('ordernumber'),
                              text: errors.first('ordernumber')
                            }
                          ]"
                        ></base-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-4">
                    <b-col sm="12">
                      <b-form-group id="groupPersonalMessage">
                        <BaseTextarea
                          name="personalmessage"
                          v-model="form.message"
                          v-validate="{max: 400}"
                          :rows="8"
                          :cols="10"
                          :placeholder="$t('customerCare.contact.write_message')"
                          :validations="[
                            {
                              condition: errors.has('personalmessage'),
                              text: $t('customerCare.contact.personalMessage')
                            }
                          ]"
                        ></BaseTextarea>
                        <p class="mt-1 mb-0">{{ totalcount }} {{ $t('customerCare.contact.charachters_left') }}</p>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="upload-file-row">
                    <b-col md="6">
                      <b-form-group id="groupUploadFile" class="upload-file-label">
                        <label class="custom-file-upload">
                          <!-- <p class="label-file-upload">{{$t('customerCare.contact.add_file')}}</p> -->
                          <input type="file" class="d-none" @change="processFile($event)" :accept="acceptFile"/>
                          <span class="upload-intro">
                            <i class="bcm-icon bcm-icon-18-plus"></i>
                            {{$t('customerCare.contact.upload_document')}}
                          </span>
                        </label>
                        <p class="mb-0 t-small">{{ $t('customerCare.contact.max_file_size') }}</p>
                        <ul class="files-list-text list-group">
                          <li v-for="(file, index) in uploadFilePath" :key="file.lastModified" class="filename-list list-group-item">
                            <button @click="removeFile(index)" class="btn btn-none font-weight-bold"><i class="bcm-icon-04-close"></i></button>
                            <span class="pr-2">{{file.name}}</span>
                          </li>
                        </ul>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-4">
                    <b-col sm="12">
                      <div class="input-box disclaimer wide">
                        <p>Le informazioni trasmesse tramite questa pagina non sono protette, pertanto si consiglia di non inserire informazioni relative alla carta di credito.</p>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="10">
                      <div class="form-end-row">
                        <b-form-group id="groupCheckBox" class="privacy-check">
                          <base-check-box
                            name="privacy"
                            v-validate="'required:true'"
                            v-model="form.privacyChecked"
                            :label="labelLinkPrivacy"
                            :validations="[
                              {
                                condition: errors.has('privacy'),
                                text: errors.first('privacy')
                              }
                            ]"
                          >
                          </base-check-box>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col cols="12" class="text-center text-sm-right mt-4">
                      <button type="submit" class="btn btn-secondary submit-form">{{ $t('send') }}</button>
                    </b-col>
                  </b-row>
                </form>
              </div>
            </b-col>
            <b-col sm="4" class="column-right">
              <div class="block-support">
                <div class="block-support__title">
                  <span>{{ $t('customerCare.contact.need-support') }}</span>
                </div>
                <div class="block-support__cta">
                  <div class="block-support__cta_single">
                    <a :href="'tel:' + $t(getNumberTel().tel)" class="btn btn-secondary">{{ $t(getNumberTel().tellabel) }}</a>
                  </div>
                  <div id="live-chat-tab" class="block-support__cta_single mt-3 live-chat-offline" @click.prevent="openChat" role="button" aria-label="tab chat" tabindex="0">
                    <a href="#" class="btn btn-secondary">{{ $t('customerCare.contact.chat') }}</a>
                  </div>
                </div>
                <p class="block-support__tail-info">{{ $t('customerCare.contact.our-customer-service') }} </p>
              </div>
            </b-col>
          </b-row>
        </div><!-- end body -->
        <div class="customer-care-content__footer">
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BaseInput from 'theme/components/Form/BaseInput'
import BaseSelect from 'theme/components/Form/BaseSelect'
import BaseTextarea from 'theme/components/Form/BaseTextarea'
import BaseCheckBox from 'theme/components/Form/BaseCheckBox'
import ModalTerms from 'theme/components/Modal/Terms'
import Config from '@/config'
import { scrollTo } from '@/helpers/utils'
// import CustomerService from '@/services/CustomerService'
import CustomerCareLink from 'theme/components/CustomerCare/CustomerCareLink.vue'
import Logger from '@/services/Logger'
import { toast } from '@/modules/notifications'
import { pathTranslate } from '@/helpers/routeHelpers'
import { navigateToPrivacyPolicy } from 'theme/helpers'
import { EventBus } from '@/helpers'

export default {
  name: 'ContactUs',
  inject: ['$validator'],
  data () {
    return {
      reasons: [],
      selectReasons: [],
      modalTerms: {
        open: false,
        pageID: ''
      },
      BrowseText: '',
      form: {
        firstname: '',
        lastname: '',
        email: '',
        country: '',
        city: '',
        reason: null,
        message: '',
        ordernumber: '',
        uploadfile: [],
        privacyChecked: false
      },
      maxFilesize: 8000000,
      exceedFileSize: false,
      uploadFilePath: [],
      acceptFile: 'image/*,.docx,.pdf,.txt'
    }
  },
  props: {
    openInPage: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    openChat () {
      if (window.zE) {
        if (this.openInPage) {
          window.zE.activate && window.zE.activate()
        } else {
          window.zE('webWidget', 'popout')
        }
      }
    },
    hideChat () {
      if (window.zE) {
        window.zE('webWidget', 'hide')
        window.zE.hide && window.zE.hide()
      }
    },
    initChat () {
      // await 2s to be sure
      setTimeout(() => {
        this.showButton = true
        this.hideChat()
      }, 2000)
    },
    onLabelClicked () {
      navigateToPrivacyPolicy()
    },
    onClickChat () {
      window.zE.activate && window.zE.activate()
      // document.querySelector('iframe#launcher').contentWindow.document.querySelector('button').click()
    },
    getReasons () {
      this.reasons.forEach(element => {
        this.selectReasons.push({ 'value': element[0], 'label': element[1], 'required': element[2] })
      })
      return this.selectReasons
    },
    openModalTerms ({ open, pageID }) {
      this.modalTerms = {
        open,
        pageID
      }
    },
    clearForm () {
      this.form.firstname = this.form.lastname = this.form.email = this.form.country = this.form.message = this.form.ordernumber = ''
      this.form.privacyChecked = false
      this.form.reason = null
      this.form.uploadfile = []
      this.$validator.reset()
    },
    renderContent (params) {
      return `<div><strong>${this.$t('customerCare.contact.contact_reason')}:</strong> <span>${params.reason.label}</span></div>
        <div><strong>${this.$t('customerCare.contact.order_number')}:</strong> <span>${params.ordernumber}</span></div>
        <div><strong>${this.$t('address.FirstName')}:</strong> <span>${params.firstname}</span></div>
        <div><strong>${this.$t('address.LastName')}:</strong> <span>${params.lastname}</span></div>
        <div><strong>${this.$t('address.Email')}:</strong> <span>${params.email}</span></div>
        <div><strong>${this.$t('address.Country')}:</strong> <span>${params.country}</span></div>
        <div><strong>${this.$t('address.City')}:</strong> <span>${params.city}</span></div>
        <div><strong>${this.$t('helpProduct.Message')}:</strong> <span>${params.message}</span></div>`
    },
    sendRequest (event) {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          return this.sendContactData(event)
        } else {
          scrollTo({
            className: 'is-invalid'
          })
        }
      })
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    sendContactData (event) {
      const _this = this
      this.$store.commit('ui/showLoader')
      let requestObject = {
        'ticketData': {
          'firstName': this.form.firstname,
          'lastName': this.form.lastname,
          'country': 'country_' + this.form.country,
          'city': this.form.city,
          'email': this.form.email,
          'reason': this.form.reason.value,
          'id': this.form.ordernumber,
          'order_increment': this.form.ordernumber,
          'subject': this.form.reason.value,
          'comment': this.form.message,
          'storeCode': this.$store.state.ui.storeViewCode
        },
        'store': 'default',
        'attachments': this.form.uploadfile
      }
      if (!this.form.message) {
        delete requestObject['ticketData']['comment']
      }
      if (!this.form.ordernumber) {
        delete requestObject['ticketData']['id']
      }
      if (!this.form.ordernumber) {
        delete requestObject['ticketData']['order_increment']
      }
      this.$store.dispatch('zendesk/create', requestObject).then((res) => {
        this.$store.commit('ui/hideLoader')
        if (res && res.status === 200) {
          event.target.reset()
          this.$validator.reset()
          this.firstName = ''
          this.lastName = ''
          this.city = ''
          this.email = ''
          this.orderNumber = ''
          this.message = ''
          toast.success(_this.$t('Request successful'))
          _this.clearForm()
        } else {
          Logger.error(res)
          toast.error(_this.$t(res.data.error_messages))
        }
      })
        .catch((err) => {
          this.$store.commit('ui/hideLoader')
          Logger.error(err)
          const data = err.response.data
          if (data && data.message) {
            toast.error(_this.$t(data.message))
          } else {
            toast.error(_this.$t('customerCare.GenericError'))
          }
        })
    },
    processFile (event) {
      if (event.target.files.length) {
        // this.form.uploadfile.push(event.target.files[0])
        const _this = this
        const file = event.target.files[0]
        console.log('event.target.file[0]', file.name)
        if (file.size > this.maxFilesize) {
          this.exceedFileSize = true
        }
        this.getBase64(file)
          .then(res => {
            // _this.form.uploadfile.push(res)
            // _this.uploadFilePath.push(file)
            let obj = {
              file: res,
              name: file.name
            }
            _this.form.uploadfile = [JSON.stringify(obj)]
            _this.uploadFilePath = [file]
          })
          .catch(err => {
            console.log('err', err)
          })
      }
    },
    removeFile (indexFile) {
      this.form.uploadfile.splice(indexFile, 1)
      this.uploadFilePath.splice(indexFile, 1)
    },
    getNumberTel () {
      let code = this.$store.state.storeConfig.config.code.split('_')
      if (code[1] === 'us') {
        return {
          tel: 'customerCare.contact.tel-us',
          tellabel: 'customerCare.contact.tel-label-us'
        }
      }
      return {
        tel: 'customerCare.contact.tel',
        tellabel: 'customerCare.contact.tel-label'
      }
    }
  },
  computed: {
    totalcount () {
      let maxCount = 400
      maxCount = maxCount - this.form.message.length
      return maxCount >= 0 ? maxCount : '0'
    },
    optionsReason () {
      const _this = this
      return Config.Theme.contactUs.reason.map(item => ({
        ...item,
        label: _this.$t(`customerCare.contact.reasons.${item.value}`)
      }))
    },
    optionsCountries () {
      return this.$store.state.checkout.countries.map(country => ({
        value: country.id,
        label: country.full_name_locale
      }))
    },
    orderRequired () {
      if (this.form.reason && this.form.reason.required) {
        return parseInt(this.form.reason.required) === 1 ? 'required' : ''
      }
      return ''
    },
    labelLinkPrivacy () {
      let linkPrivacy = this.$router.resolve({ name: 'genericTerms', params: { slug: pathTranslate({ path: 'privacy' }), lang: this.$store.state.ui.urlCode } }).href
      return this.$t('termsPrivacy', { linkPrivacy })
    }
  },
  created () {
    const _this = this
    this.$store.dispatch('zendesk/contactReasons').then((resp) => {
      _this.reasons = resp.data
      _this.getReasons()
    }).catch((err) => {
      Logger.error(err)
    })
  },
  mounted () {
    EventBus.$on('chat-loaded', this.initChat)
    EventBus.$on('chat::open', this.openChat)
    this.$store.dispatch('checkout/loadCountries')
    this.clearForm()
  },
  destroyed () {
    EventBus.$off('chat-loaded', this.initChat)
    EventBus.$off('chat::open', this.openChat)
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseTextarea,
    BaseCheckBox,
    ModalTerms,
    CustomerCareLink
  }
}
</script>

<style lang="scss" scoped>
ul{
  padding: 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px #ececec solid;

  li {
    margin-bottom: 15px;
    padding: 0;
    margin: 0 5px !important;
    .bcm-link-primary {
      &::after {
        width: 0;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }

      &.active,
      &.router-link-active {
        &::after {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss">

// hide chat icon only when this component is living
#page-ContactUs #chat-overlay {
   position: fixed;
    width:70px;
    height:70px;
    /* top: 445px; */
    right: 10px;
    /* margin-top: -157px; */
    bottom:50px;
    cursor:pointer;
}
#live-chat-tab {
      img {
        width:70px;
        height:70px;
        max-width: 100%;
      }
    }

</style>
